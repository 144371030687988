<template>
  <div>
    <ExNvrDiagnosticsPanelSectionHeader :title="title" :tasks="relatedTasks" />
    <div class="d-flex">
      <div
        v-for="(list, index) in configLists"
        :key="index"
        class="ex-nvr-diagnostics__result"
        :class="{
          'pl-2': index !== 0,
          'ex-nvr-diagnostics__result--disabled': list.isDisabled,
        }"
      >
        <ExNvrDiagnosticsPanelSectionConfigsList
          :items="list.items"
          :grid-layout="list.gridLayout"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import ExNvrDiagnosticsPanelSectionConfigsList from "@/components/nvrs/ExNvrDiagnosticsPanelSectionConfigsList"
import ExNvrDiagnosticsPanelSectionHeader from "@/components/nvrs/ExNvrDiagnosticsPanelSectionHeader"
import {
  ExNvrConfigList,
  ExNvrHealthCheckTaskId,
  ExNvrTaskWithDescription,
} from "@evercam/shared/types"

export default Vue.extend({
  components: {
    ExNvrDiagnosticsPanelSectionConfigsList,
    ExNvrDiagnosticsPanelSectionHeader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    relatedTasks: {
      type: Array as PropType<
        Array<ExNvrTaskWithDescription<ExNvrHealthCheckTaskId>>
      >,
      required: true,
    },
    configLists: {
      type: Array as PropType<Array<ExNvrConfigList>>,
      required: true,
    },
  },
})
</script>
