<template>
  <div class="d-flex pt-2 pb-0">
    <h3>{{ title }}</h3>
    <TasksProgressIndicator
      :tasks="tasks.filter((t) => !hiddenTasks.includes(t.id))"
      :show-total-progress="false"
      :use-evercam-loading-indicator="false"
      row
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import TasksProgressIndicator from "@/components/TasksProgressIndicator.vue"
import {
  ExNvrHealthCheckTaskId,
  TaskProgressItem,
  TaskStatus,
} from "@evercam/shared/types"
export default Vue.extend({
  name: "ExNvrDiagnosticsHeader",
  components: {
    TasksProgressIndicator,
  },
  data() {
    return {
      hiddenTasks: [
        ExNvrHealthCheckTaskId.CameraTimeConfigCheck,
        ExNvrHealthCheckTaskId.CameraSystemConfigCheck,
      ],
    }
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tasks: {
      type: Array as PropType<Array<TaskProgressItem>>,
      required: true,
    },
  },
  computed: {
    TaskStatus() {
      return TaskStatus
    },
  },
})
</script>
