var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"progress-list d-flex justify-center align-center"},[(_vm.showTotalProgress)?_c('div',{staticClass:"progress-list__total"},[_c('v-progress-circular',{staticClass:"mr-4",attrs:{"rotate":360,"size":80,"width":3,"value":_vm.hasError ? 100 : _vm.totalProgress,"color":_vm.hasError ? 'error' : 'success'}},[(_vm.hasError)?_c('v-icon',{staticClass:"progress-list__total-icon",attrs:{"color":"error","x-large":""}},[_vm._v("\n        fa-xmark\n      ")]):(_vm.totalProgress < 100)?_c('h3',{staticClass:"font-weight-500"},[_vm._v("\n        "+_vm._s(_vm.totalProgress)+"%\n      ")]):_c('v-icon',{staticClass:"progress-list__total-icon",attrs:{"color":"success","x-large":""}},[_vm._v("\n        fa-check\n      ")])],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-inline-flex justify-start flex-nowrap",class:_vm.row ? '' : 'flex-column'},_vm._l((_vm.tasks),function(task,i){return _c('div',{key:i,staticClass:"progress-list__item d-inline-flex justify-start align-center",class:{
        'progress-list__item--done': task.status === _vm.TaskStatus.Success,
        'progress-list__item--error': task.status === _vm.TaskStatus.Error,
        'progress-list__item--loading': task.status === _vm.TaskStatus.Loading,
        'my-1': !_vm.row,
      }},[_c('div',{staticClass:"progress-list__item__icon d-flex justify-center align-center"},[([_vm.TaskStatus.Idle, _vm.TaskStatus.Loading].includes(task.status))?_c('div',{staticClass:"d-inline"},[(
              !_vm.showPercentForTask(task) &&
              !_vm.isIdle(task) &&
              _vm.useEvercamLoadingIndicator
            )?_c('EvercamLoadingAnimation',{attrs:{"size":"Md"}}):(!_vm.useEvercamLoadingIndicator && !_vm.isIdle(task))?_c('v-progress-circular',{attrs:{"size":14,"width":2,"indeterminate":"","color":"primary"}}):_vm._e(),_vm._v(" "),(_vm.showPercentForTask(task))?_c('v-progress-circular',{staticClass:"position-absolute opacity-20",attrs:{"size":14,"width":2,"color":"primary"}}):_vm._e()],1):(task.status === _vm.TaskStatus.Success)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("\n          fa-check\n        ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" fa-xmark ")])],1),_vm._v(" "),_c('div',{staticClass:"progress-list__item__text"},[_vm._v("\n        "+_vm._s(task.description)+"\n        "),(task.duration)?_c('small',[_c('strong',[_vm._v(" ("+_vm._s(_vm.formatDuration(task.duration))+") ")])]):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }