<template>
  <div v-resize-observer="onCardResized" class="ex-nvr-camera-item">
    <div class="grid-row ex-nvr-card">
      <div class="grid-cell position-relative" :style="thumbnailStyles">
        <ExNvrCameraItemSnapshot
          ref="thumbnail"
          :snapshot-url="snapshotUrl"
          :cr-snapshot-url="crSnapshotUrl"
          :height="showDiagnosticsPanel ? cardHeight : thumbnailMinHeight"
          :expanded="showDiagnosticsPanel"
        />
      </div>

      <!-- STATUS -->
      <div class="grid-cell pl-3">
        <span :class="getStatusColor(camera.status)">
          {{ getStatusText(camera.status) }}
        </span>
      </div>

      <!-- GENERAL -->
      <div class="grid-cell">
        <div class="d-flex">
          <div class="mr-2">Camera</div>
          <span
            class="cursor-pointer primary--text"
            @click="cameraDialogStore.openDialog({ camera: camera.exid })"
            >{{ camera.name }} ({{ camera.exid }})
          </span>
        </div>
        <div class="d-flex">
          <div class="mr-2">Project</div>
          <span
            class="cursor-pointer primary--text"
            @click="projectStore.openDialog(camera.projectExid)"
            >{{ camera.projectName }} ({{ camera.projectExid }})
          </span>
        </div>
      </div>

      <!-- URLs -->
      <div class="grid-cell">
        <div class="d-flex">
          <div class="mr-2">NVR</div>
          <a :href="nvrApiUrl">{{ nvrApiUrl }}</a>
        </div>
        <div class="d-flex">
          <div class="mr-2">Cam</div>
          <a :href="cameraUrl">{{ cameraUrl }}</a>
        </div>
      </div>

      <!-- DATES -->
      <div class="grid-cell">{{ camera.createdAt.split(".")[0] }}</div>

      <!-- ACTIONS -->
      <div class="grid-cell status-label d-flex align-center justify-center">
        <v-btn
          :color="isErrored ? 'error' : 'success'"
          small
          class="white--text"
          :disabled="isLoading"
          :class="{ 'mr-4': !(isIdle || isLoading) }"
          @click="startHealthCheck"
        >
          <EvercamLoadingAnimation v-if="isLoading" size="Md" class="mr-2" />
          <v-icon v-else-if="healthCheckStatusIcon" small class="mr-2">
            {{ healthCheckStatusIcon }}
          </v-icon>
          {{ isIdle || isLoading ? "Diagnose" : "Re-Diagnose" }}
          <v-icon small class="ml-2"> fas fa-heart-pulse </v-icon>
        </v-btn>
      </div>
      <v-btn
        v-if="!isIdle"
        class="expand-button"
        icon
        small
        plain
        @click="toggleExNvrDiagnosticsPanelVisibility"
      >
        <v-icon small color="black">
          fas fa-chevron-{{ isExpanded ? "up" : "down" }}
        </v-icon>
      </v-btn>
    </div>
    <div
      v-if="showDiagnosticsPanel"
      class="diagnostics-panel"
      :style="diagnosticsPanelStyles"
    >
      <ExNvrDiagnosticsPanel :camera="camera" :tasks="healthCheckTasks" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import {
  AdminCamera,
  TaskStatus,
  ExNvrHealthCheckTaskId,
  ExNvrHealthCheckTask,
} from "@evercam/shared/types"
import ExNvrCameraItemSnapshot from "@/components/nvrs/ExNvrCameraItemSnapshot"
import ExNvrDiagnosticsPanel from "@/components/nvrs/ExNvrDiagnosticsPanel"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import CameraUtils from "@/mixins/cameraUtils"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useProjectStore } from "@/stores/projects"

export default Vue.extend({
  components: {
    ExNvrDiagnosticsPanel,
    ExNvrCameraItemSnapshot,
    EvercamLoadingAnimation,
  },
  mixins: [CameraUtils],
  props: {
    camera: {
      type: Object as PropType<AdminCamera>,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    healthCheckTasks: {
      type: Array as PropType<
        Array<ExNvrHealthCheckTask<ExNvrHealthCheckTaskId>>
      >,
      required: true,
    },
    thumbnailMinHeight: {
      type: Number,
      default: 96,
    },
  },
  data() {
    return {
      streamingToken: "",
      isReady: false,
      isDown: false,
      error: null,
      isDiagnosticsPanelVisible: false,
      cardHeight: this.thumbnailMinHeight,
      thumbnailWidth: 0,
      isExpanded: false,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useProjectStore),
    timezone(): string {
      return this.camera.timezone || "Europe/Dublin"
    },
    nvrApiUrl(): string {
      return `${this.camera.nvrScheme}://${this.camera.nvrHost}:${this.camera.nvrHttpPort}`
    },
    cameraUrl(): string {
      return `${this.camera.cameraScheme}://${this.camera.cameraHost}:${this.camera.cameraHttpPort}`
    },
    snapshotUrl(): string {
      if (!this.isReady) {
        return ""
      }

      return `${this.nvrApiUrl}/api/devices/${this.camera.nvrDeviceId}/snapshot?access_token=${this.streamingToken}`
      // return "https://picsum.photos/200/300"
    },
    crSnapshotUrl(): string {
      return `${this.$config.public.apiURL}/cameras/${this.camera.exid}/thumbnail?api_id=${this.camera.userApiId}&api_key=${this.camera.userApiKey}`
    },
    isIdle(): boolean {
      return this.healthCheckTasks.reduce(
        (acc, t) => acc && t.status === TaskStatus.Idle,
        true
      )
    },
    isLoading(): boolean {
      return this.healthCheckTasks.reduce(
        (acc, t) => acc || t.status === TaskStatus.Loading,
        false
      )
    },
    isErrored(): boolean {
      return this.healthCheckTasks.reduce(
        (acc, t) => acc || t.status === TaskStatus.Error,
        false
      )
    },
    isSucceeded(): boolean {
      return this.healthCheckTasks.reduce(
        (acc, t) => acc || t.status === TaskStatus.Success,
        false
      )
    },
    healthCheckStatusIcon() {
      if (this.isErrored) {
        return "fa-xmark"
      }

      if (this.isSucceeded) {
        return "fa-check"
      }

      return ""
    },
    showDiagnosticsPanel(): boolean {
      return !this.isIdle && this.isExpanded
    },
    thumbnailStyles(): Record<string, string> {
      return { height: `${this.thumbnailMinHeight}px` }
    },
    diagnosticsPanelStyles(): Record<string, string> {
      return { marginLeft: `${this.thumbnailWidth}px` }
    },
  },
  methods: {
    onCardResized({ contentRect }) {
      this.cardHeight = contentRect.height
      this.thumbnailWidth =
        // @ts-ignore
        (this.$refs.thumbnail?.$el as HTMLElement)?.getBoundingClientRect()
          ?.width || 0
      this.$emit("height-change", this.cardHeight)
    },
    toggleExNvrDiagnosticsPanelVisibility() {
      this.isExpanded = !this.isExpanded
    },
    startHealthCheck() {
      this.$emit("start-health-check")
      this.isExpanded = true
    },
  },
})
</script>

<style scoped lang="scss">
.ex-nvr-card {
  position: relative;
  .expand-button {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
</style>
