<template>
  <div
    class="position-relative ma-2 pa-2 d-flex flex-column justify-start white rounded"
  >
    <p class="text-subtitle-1">Cameras count by health check status</p>
    <v-chip
      v-for="statusItem in healthCheckStatuses"
      :key="statusItem.status"
      :color="statusItem.color"
      :outlined="selectedHealthCheckStatus !== statusItem.status"
      class="cursor-pointer font-weight-medium my-1"
      :class="{
        'selected-chip': selectedHealthCheckStatus === statusItem.status,
      }"
      @click="onChipClick(statusItem)"
    >
      <v-icon left>{{ statusItem.icon }}</v-icon>
      {{ statusItem.text }}: {{ statusItem.count }}
    </v-chip>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { useExNvrHealthCheckStore } from "@/stores/exNvrHealthCheck"
import { mapStores } from "pinia"
import {
  ExNvrHealthCheckTask,
  AdminCamera,
  TaskStatus,
} from "@evercam/shared/types"

enum CameraHealthCheckStatus {
  Diagnosing = "Diagnosing",
  Failed = "Failed",
  Successfull = "Successfull",
  Remaining = "Remaining",
  All = "All",
}

type CameraHealthCheckStatusItem = {
  icon: string
  color: string
  text: string
  status: CameraHealthCheckStatus
  count: number
  cameras?: AdminCamera[]
}

export default Vue.extend({
  name: "ExNvrCamerasHealthCheckStats",
  props: {
    cameras: {
      type: Array as PropType<Array<AdminCamera>>,
      default: () => [],
    },
  },
  data() {
    return {
      selectedHealthCheckStatus: CameraHealthCheckStatus.All,
    }
  },
  computed: {
    ...mapStores(useExNvrHealthCheckStore),
    healthCheckStatuses(): CameraHealthCheckStatusItem[] {
      const camerasByHealthCheckStatus = this.cameras.reduce(
        (cameras, camera) => {
          const status = this.getCameraHealthCheckStatus(camera)

          switch (status) {
            case TaskStatus.Success:
              cameras.successfull.push(camera)
              break
            case TaskStatus.Loading:
              cameras.diagnosing.push(camera)
              break
            case TaskStatus.Error:
              cameras.failed.push(camera)
              break
            case TaskStatus.Idle:
              cameras.remaining.push(camera)
              break
          }

          return cameras
        },
        { successfull: [], diagnosing: [], failed: [], remaining: [] }
      )

      return [
        {
          icon: "fa-spinner",
          color: "primary",
          text: "Diagnosing",
          status: CameraHealthCheckStatus.Diagnosing,
          count: camerasByHealthCheckStatus.diagnosing?.length,
          cameras: camerasByHealthCheckStatus.diagnosing,
        },
        {
          icon: "fa-check",
          color: "success",
          text: "Successfull",
          status: CameraHealthCheckStatus.Successfull,
          count: camerasByHealthCheckStatus.successfull?.length,
          cameras: camerasByHealthCheckStatus.successfull,
        },
        {
          icon: "fa-xmark",
          color: "error",
          text: "Failed",
          status: CameraHealthCheckStatus.Failed,
          count: camerasByHealthCheckStatus.failed?.length,
          cameras: camerasByHealthCheckStatus.failed,
        },
        {
          icon: "fa-clock",
          color: "black",
          text: "Remaining",
          status: CameraHealthCheckStatus.Remaining,
          count: camerasByHealthCheckStatus.remaining?.length,
          cameras: camerasByHealthCheckStatus.remaining,
        },
        {
          icon: "fa-chart-pie",
          color: "black",
          text: "Total",
          status: CameraHealthCheckStatus.All,
          count: this.cameras?.length,
        },
      ]
    },
  },
  watch: {
    healthCheckStatuses: {
      handler() {
        const healthCheckStatusItem = this.healthCheckStatuses.find(
          (item) => item.status === this.selectedHealthCheckStatus
        )
        this.sendCamerasByHealthCheckStatusSelection(healthCheckStatusItem)
      },
      deep: true,
    },
  },
  methods: {
    getHealthCheckTasksForCamera(
      camera: AdminCamera
    ): ExNvrHealthCheckTask<any>[] {
      return this.exNvrHealthCheckStore.healthCheckTasksByCameraExid[
        camera.exid
      ]
    },
    getCameraHealthCheckStatus(camera: AdminCamera): TaskStatus {
      const healthCheckTasks = this.getHealthCheckTasksForCamera(camera)
      const isLoading = healthCheckTasks.find(
        (t) => t.status === TaskStatus.Loading
      )

      if (isLoading) {
        return TaskStatus.Loading
      }

      const isErrored = healthCheckTasks.find(
        (t) => t.status === TaskStatus.Error
      )

      if (isErrored) {
        return TaskStatus.Error
      }

      const isSucceeded = healthCheckTasks.every(
        (t) => t.status === TaskStatus.Success
      )

      if (isSucceeded) {
        return TaskStatus.Success
      }

      return TaskStatus.Idle
    },
    onChipClick(healthCheckStatusItem) {
      if (this.selectedHealthCheckStatus === healthCheckStatusItem.status) {
        this.selectedHealthCheckStatus = CameraHealthCheckStatus.All
      } else {
        this.selectedHealthCheckStatus = healthCheckStatusItem.status
      }
      this.sendCamerasByHealthCheckStatusSelection(healthCheckStatusItem)
    },
    sendCamerasByHealthCheckStatusSelection(healthCheckStatusItem) {
      this.$emit(
        "cameras-by-selected-health-check-status",
        this.selectedHealthCheckStatus !== CameraHealthCheckStatus.All
          ? healthCheckStatusItem?.cameras
          : null
      )
    },
  },
})
</script>

<style scoped>
.selected-chip {
  color: white;
}
</style>
