<template>
  <v-img
    :src="useNvrSnapshot ? snapshotUrl : crSnapshotUrl"
    aspect-ratio="1.77"
    class="nvr-snapshot white--text"
    :class="{
      'nvr-snapshot--expanded': expanded,
    }"
    :height="height"
    @error="onSnapshotError"
    @load="onSnapshotLoad"
  >
    <!-- Loading slot -->
    <template #placeholder>
      <v-img
        v-if="useNvrSnapshot"
        :src="crSnapshotUrl"
        aspect-ratio="1.77"
        class="white--text"
        :height="height"
      >
        <v-layout
          fill-height
          align-center
          justify-center
          ma-0
          class="d-flex flex-column"
        >
          <v-progress-circular
            v-if="!isSnapshotError"
            indeterminate
            color="white"
            width="2"
          ></v-progress-circular>
        </v-layout>
      </v-img>
      <v-layout
        v-else
        fill-height
        align-center
        justify-center
        ma-0
        class="d-flex flex-column"
      >
        <v-progress-circular
          v-if="!isSnapshotError"
          indeterminate
          color="white"
          width="2"
        ></v-progress-circular>
      </v-layout>
    </template>

    <!-- Error slot -->
    <template #error>
      <v-layout fill-height align-center justify-center ma-0>
        <v-icon color="red" size="56">mdi-alert-circle</v-icon>
      </v-layout>
    </template>
    <div class="nvr-snapshot__label" :style="labelStyles">
      {{ label }}
    </div>
  </v-img>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    crSnapshotUrl: {
      type: String,
      default: "",
    },
    snapshotUrl: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 128,
    },
    useNvrSnapshot: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSnapshotError: false,
      isSnapshotLoaded: false,
    }
  },
  computed: {
    label(): string {
      if (!this.useNvrSnapshot) {
        return "CR Snapshot"
      }

      if (this.isSnapshotError) {
        return "NVR Snapshot error."
      }

      return this.isSnapshotLoaded
        ? "NVR Snapshot loaded."
        : "NVR Snapshot loading..."
    },
    labelStyles(): Record<string, string> {
      let background = "rgba(82,82,82,0.82)"

      if (this.useNvrSnapshot && this.isSnapshotLoaded) {
        background = "rgb(59, 126, 19, 0.71)"
      } else if (this.useNvrSnapshot && this.isSnapshotError) {
        background = "rgba(255,0,0,0.66)"
      }

      return {
        background,
      }
    },
  },
  methods: {
    onSnapshotError(e) {
      console.error(e)
      this.isSnapshotError = true
    },
    onSnapshotLoad() {
      this.isSnapshotLoaded = true
    },
  },
})
</script>

<style lang="scss">
.nvr-snapshot {
  &__label {
    position: absolute;
    bottom: 0;
    font-size: 12px;
    height: 22px;
    align-items: center;
    display: flex;
    padding-left: 9px;
    width: 100%;
    background: #00000099;
  }
  &--expanded {
    position: absolute;
    width: 100%;
    top: 0;
    box-shadow: 4px 0 2px -4px #000;
  }
}
</style>
