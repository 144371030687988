<template>
  <div class="progress-list d-flex justify-center align-center">
    <!-- Total progress -->
    <div v-if="showTotalProgress" class="progress-list__total">
      <v-progress-circular
        :rotate="360"
        :size="80"
        :width="3"
        :value="hasError ? 100 : totalProgress"
        :color="hasError ? 'error' : 'success'"
        class="mr-4"
      >
        <v-icon
          v-if="hasError"
          class="progress-list__total-icon"
          color="error"
          x-large
        >
          fa-xmark
        </v-icon>
        <h3 v-else-if="totalProgress < 100" class="font-weight-500">
          {{ totalProgress }}%
        </h3>
        <v-icon
          v-else
          class="progress-list__total-icon"
          color="success"
          x-large
        >
          fa-check
        </v-icon>
      </v-progress-circular>
    </div>

    <!-- Tasks list -->
    <div
      class="d-inline-flex justify-start flex-nowrap"
      :class="row ? '' : 'flex-column'"
    >
      <div
        v-for="(task, i) in tasks"
        :key="i"
        class="progress-list__item d-inline-flex justify-start align-center"
        :class="{
          'progress-list__item--done': task.status === TaskStatus.Success,
          'progress-list__item--error': task.status === TaskStatus.Error,
          'progress-list__item--loading': task.status === TaskStatus.Loading,
          'my-1': !row,
        }"
      >
        <!-- Icon indicator -->
        <div
          class="progress-list__item__icon d-flex justify-center align-center"
        >
          <div
            v-if="[TaskStatus.Idle, TaskStatus.Loading].includes(task.status)"
            class="d-inline"
          >
            <!-- Idle / Loading -->
            <EvercamLoadingAnimation
              v-if="
                !showPercentForTask(task) &&
                !isIdle(task) &&
                useEvercamLoadingIndicator
              "
              size="Md"
            />
            <v-progress-circular
              v-else-if="!useEvercamLoadingIndicator && !isIdle(task)"
              :size="14"
              :width="2"
              indeterminate
              color="primary"
            />
            <!-- Loading overlay -->
            <v-progress-circular
              v-if="showPercentForTask(task)"
              :size="14"
              :width="2"
              color="primary"
              class="position-absolute opacity-20"
            />
          </div>

          <!-- Success -->
          <v-icon
            v-else-if="task.status === TaskStatus.Success"
            color="success"
          >
            fa-check
          </v-icon>

          <!-- Error -->
          <v-icon v-else color="error"> fa-xmark </v-icon>
        </div>

        <!-- Description -->
        <div class="progress-list__item__text">
          {{ task.description }}
          <small v-if="task.duration">
            <strong> ({{ formatDuration(task.duration) }}) </strong>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { TaskProgressItem, TaskStatus } from "@evercam/shared/types"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default Vue.extend({
  name: "TasksProgressIndicator",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    tasks: {
      type: Array as PropType<Array<TaskProgressItem>>,
      default: () => [],
    },
    showTotalProgress: {
      type: Boolean,
      default: true,
    },
    row: {
      type: Boolean,
      default: false,
    },
    useEvercamLoadingIndicator: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      TaskStatus,
    }
  },
  computed: {
    totalProgress() {
      const ratio =
        this.tasks.reduce((acc, t) => acc + this.getProgressValue(t), 0) /
        this.tasks.length

      return Math.floor(ratio)
    },
    hasError() {
      return this.tasks.find((t) => t.status === TaskStatus.Error)
    },
  },
  methods: {
    getProgressValue(task: TaskProgressItem): number {
      if (isNaN(task.percentDone)) {
        return task.status === TaskStatus.Success ? 100 : 0
      } else {
        return task.percentDone
      }
    },
    isIdle(task) {
      return task.status === TaskStatus.Idle
    },
    showPercentForTask(task: TaskProgressItem): boolean {
      if (task.status !== TaskStatus.Loading) {
        return false
      }

      return !isNaN(task.percentDone)
    },
    formatDuration(duration: number): string {
      if (duration >= 1000) {
        return `${(duration / 1000).toFixed(2)}s`
      } else {
        return `${Math.round(duration)}ms`
      }
    },
  },
})
</script>

<style lang="scss">
.progress-list {
  &__total-icon.v-icon {
    font-size: 24px !important;
  }
  &__item {
    &__text {
      color: #333;
    }
    &__icon {
      width: 1.5rem;
      margin-left: 0.25rem;
    }
    &--error {
      .progress-list__item__text {
        color: #c73629;
      }
    }
  }
}
</style>
