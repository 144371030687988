<template>
  <div class="nvr-filters pt-4">
    <div class="grid-header">Filters</div>

    <div class="grid-surface grid-filters pa-2">
      <slot name="actions"></slot>
      <!-- GLOBAL SEARCH -->
      <EGlobalSearch
        :dark="$vuetify.theme.dark"
        :items="searchInputItems"
        :is-fixed-position="false"
        :is-hidden="false"
        class="ma-2"
        :deep="false"
        @search-results="onGlobalSearchResultsChange"
        @reset="onGLobalSearchResultsReset"
      />

      <!-- CAMERA MODEL -->
      <v-select
        v-model="selectedCameraModel"
        outlined
        dense
        hide-details
        label="Camera Model"
        :items="cameraModels"
        item-text="name"
        item-value="value"
        :menu-props="{ maxHeight: 'none' }"
        class="color-select ma-2 white"
        @change="(s) => (selectedCameraModel = s)"
      />

      <!-- CAMERA FEATURE FLAG -->
      <v-select
        v-model="selectedFeatureFlag"
        outlined
        dense
        hide-details
        label="Camera Feature Flag"
        :items="featureFlags"
        :menu-props="{ maxHeight: 'none' }"
        class="color-select ma-2 white"
        @change="(s) => (selectedFeatureFlag = s)"
      />

      <!-- CAMERA CREATION DATE -->
      <v-menu
        v-model="isCreationDatePickerOpened"
        :close-on-content-click="false"
        max-width="290"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="creationDateRangeDisplay"
            label="Camera Creation Date"
            placeholder="Camera Creation Date"
            persistent-placeholder
            readonly
            clearable
            dense
            outlined
            hide-details
            :menu-props="{ maxHeight: 'none' }"
            class="color-select ma-2 white"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="creationDateRange"
          range
          @change="onCreationDateRangeChange"
        />
      </v-menu>

      <!-- CAMERA STATUS -->
      <v-combobox
        v-model="selectedCameraStatus"
        :items="cameraStatuses"
        label="Camera Status"
        class="ma-2 white"
        item-text="name"
        item-value="name"
        multiple
        dense
        outlined
        chips
        small-chips
        :filter="customFilter"
        :menu-props="{
          closeOnClick: true,
          persistent: false,
        }"
        @keydown="handleKeydown"
      />
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { CameraFeatureFlags, CameraStatuses } from "@/components/constants.js"
import CameraUtils from "@/mixins/cameraUtils"

import {
  AdminCamera,
  CameraStatus,
  CameraModel,
  GlobalSearchResult,
  CameraFeatureFlag,
} from "@evercam/shared/types"

export default Vue.extend({
  name: "ExNvrFiltersPanel",
  mixins: [CameraUtils],
  props: {
    cameras: {
      type: Array as PropType<AdminCamera[]>,
      required: true,
    },
  },
  data() {
    return {
      globalSearchResults: [] as GlobalSearchResult[],
      searchResults: [] as AdminCamera[],
      isSearchActive: false,
      selectedCameraModel: "",
      selectedCameraStatus: [{ name: "Online", value: CameraStatus.Online }],
      selectedFeatureFlag: "" as CameraFeatureFlag,
      isCreationDatePickerOpened: false,
      creationDateRange: [],
    }
  },
  computed: {
    creationDateRangeDisplay: {
      get() {
        return this.creationDateRange?.join(" - ")
      },
      set() {
        this.creationDateRange = []
      },
    },
    cameraModels() {
      const cameraModels = Object.values(CameraModel).map((value) => ({
        value,
        name: value,
      }))

      return [{ name: "All", value: "" }, ...cameraModels]
    },
    cameraStatuses() {
      return [...CameraStatuses]
    },
    featureFlags() {
      const featureFlags = CameraFeatureFlags.map(({ value, name: text }) => ({
        text,
        value,
      }))

      return [{ text: "All", value: "" }, ...featureFlags]
    },
    searchInputItems(): Record<any, any>[] {
      return this.cameras.map((c) => [
        c.name,
        c.projectExid,
        c.exid,
        c.projectName,
        c.createdAt,
        c.cameraModel,
        c.status,
        c.userFullname,
      ])
    },
    filteredCameras(): AdminCamera[] {
      let filteredCameras = this.cameras

      if (this.isSearchActive) {
        filteredCameras = this.globalSearchResults.reduce<AdminCamera[]>(
          (acc, match) => {
            return [...acc, this.cameras[match.index]]
          },
          []
        )
      }

      return filteredCameras.filter((c) => {
        const matchesFeatureFlag =
          !this.selectedFeatureFlag ||
          c.featureFlags?.includes(this.selectedFeatureFlag)

        const matchesDateRange =
          !this.creationDateRange.length ||
          (this.$moment(c.createdAt).isSameOrAfter(
            this.$moment(this.creationDateRange[0])
          ) &&
            this.$moment(c.createdAt).isSameOrBefore(
              this.$moment(this.creationDateRange[1])
            ))
        this.selectedCameraStatus = this.selectedCameraStatus.filter(
          (status) => typeof status === "object" && status !== null
        )

        const selectedValues = this.selectedCameraStatus.map(
          (status) => status.value
        )
        const matchesCameraStatus =
          !this.selectedCameraStatus.length || selectedValues.includes(c.status)

        const matchesCameraModel =
          !this.selectedCameraModel ||
          c.cameraModel === this.selectedCameraModel

        return (
          matchesCameraModel &&
          matchesCameraStatus &&
          matchesFeatureFlag &&
          matchesDateRange
        )
      })
    },
  },
  watch: {
    filteredCameras: {
      immediate: true,
      handler(v) {
        this.$emit("change", v)
      },
    },
  },
  methods: {
    onGlobalSearchResultsChange(results: GlobalSearchResult[]) {
      this.isSearchActive = true
      this.globalSearchResults = results
    },
    onGLobalSearchResultsReset() {
      this.isSearchActive = false
    },
    onCreationDateRangeChange() {
      this.isCreationDatePickerOpened = false
      if ((this.creationDateRange || [])?.length < 2) {
        return
      }

      if (
        this.$moment(this.creationDateRange[0]).isAfter(
          this.$moment(this.creationDateRange[1])
        )
      ) {
        this.creationDateRange = [
          this.creationDateRange[1],
          this.creationDateRange[0],
        ]
      }
    },
    handleKeydown(event) {
      if (event.key === "Tab" || event.key === "Enter") {
        this.handleTabAndEnterClick(event)
      }
    },
    customFilter(item, queryText) {
      const query = queryText.toLowerCase()

      return item.name.toLowerCase().includes(query)
    },
    handleTabAndEnterClick(event) {
      const inputValue = event.target.value.toLowerCase()
      const matchedItem = this.cameraStatuses.find(
        (item) => item.name.toLowerCase() === inputValue
      )

      if (matchedItem && !this.selectedCameraStatus.includes(matchedItem)) {
        this.selectedCameraStatus.push(matchedItem) // Add to selected values
      }
    },
  },
})
</script>

<style>
.nvr-filters .v-text-field__details {
  display: none;
}
.nvr-filters .v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}
</style>
