<template>
  <div class="ex-nvr-config-list">
    <div v-for="(item, index) in items" :key="index">
      <div
        :class="getItemClasses(item)"
        class="ex-nvr-config-list-item"
        :style="itemStyles"
      >
        <strong
          class="mr-2 ex-nvr-config-list__label"
          :style="getLabelStyles(item)"
        >
          {{ item.label }}
        </strong>
        <div
          v-if="item.value"
          class="ex-nvr-config-list__value"
          :style="getValueStyles(item)"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { ExNvrConfigListItem } from "@evercam/shared/types"

export default Vue.extend({
  name: "ExNvrConfigList",
  components: {},
  props: {
    items: {
      type: Array as PropType<Array<ExNvrConfigListItem>>,
      required: true,
    },
    gridLayout: {
      type: String,
      default: "1.5fr 2fr",
    },
  },
  computed: {
    itemStyles(): Record<string, string> {
      return {
        gridTemplateColumns: this.gridLayout,
      }
    },
  },
  methods: {
    getItemClasses(item: ExNvrConfigListItem): Record<string, boolean> {
      return {
        "ex-nvr-config-list-item--disabled": item.disabled,
      }
    },
    getValueStyles(item: ExNvrConfigListItem): Record<string, string> {
      if (!item.valueColor) {
        return {}
      }

      return {
        color: item.valueColor,
      }
    },
    getLabelStyles(item: ExNvrConfigListItem): Record<string, string> {
      if (!item.labelColor) {
        return {}
      }

      return {
        color: item.labelColor,
      }
    },
  },
})
</script>

<style>
.ex-nvr-config-list-item {
  display: grid;
  align-items: center;
  gap: 4px;
}
.ex-nvr-config-list__label,
.ex-nvr-config-list__value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ex-nvr-config-list-item:hover .ex-nvr-config-list__label,
.ex-nvr-config-list-item:hover .ex-nvr-config-list__value {
  background: rgb(233, 236, 239);
  overflow: visible;
  text-overflow: clip;
}
.ex-nvr-config-list-item--disabled {
  color: #3337;
}
</style>
